<template>
    <b-alert v-if="event.canceled_at" class="shadow" variant="warning" show>
        <b-row align-v="center">
            <b-col class="text-center text-md-right order-md-1" md="2">
                <font-awesome-icon class="mb-1 mb-md-0" icon="exclamation-triangle" size="2x" />
            </b-col>
            <b-col class="text-center text-md-left">
                <h5 class="font-weight-bold mb-0">Dit evenement is geannuleerd</h5>
                <div>Bezoekers zijn reeds geinformeerd en je kunt dit evenement niet meer bewerken.</div>
            </b-col>
        </b-row>
    </b-alert>
</template>

<script>
    export default {
        props: {
            event: Object
        },
    }
</script>