<template>
    <div>
        <b-container class="mt-5 mb-5">
            <h1 class="fs-4 font-weight-bold mb-4">Bewerk evenement</h1>
            <EventCanceledAlert :event="form" />

            <ValidationObserver ref="form" slim>
                <EventPersonal :form="form" />
            </ValidationObserver>
    
            <div class="clearfix">
                <template v-if="!form.canceled_at">
                    <b-button @click="submitForm" variant="primary" class="float-right">
                        Opslaan
                        <font-awesome-icon icon="fa-solid fa-chevron-right" />
                    </b-button>
                </template>
            </div>
            
        </b-container>
    </div>
</template>
  
<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'

    import { ValidationObserver } from 'vee-validate';
    import EventCanceledAlert from '@/components/event/EventCanceledAlert'
    import EventPersonal from '@/components/event/EventPersonal'

    export default {
        metaInfo: {
            title: 'Bewerk evenement',
        },
        components: {
            EventCanceledAlert,
            ValidationObserver,
            EventPersonal,
        },
        data() {
            return {
                form: {
                    slots: []
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions(useShopStore, ['setLoading']),
            fetchEvent: function() {
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id)
                    .then( response => {
                        this.setLoading(false);
                        this.form = response.data;
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            async submitForm() {
                let check = await this.$refs['form'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        havior: "smooth"
                    });
                    
                    return;
                }

                this.$axios.put("https://api.tixgo.nl/events/" + this.$route.params.id, this.form)
                    .then( response => {
                        response;
                        this.$router.push('/manage/events');
                    })
                ;
            }
        },
        created() {
            this.fetchEvent();
        },
    }
</script>