<template>
    <div>
        <b-card class="mb-4">
        <h3 class="fs-4 font-weight-bold mb-0">Bestelgegevens</h3>
        <p class="text-muted">Welke gegevens wil je weten van de klant.</p>
        <div class="mb-3">
            <label>Welke (extra) gegevens wil je weten van de klant?</label>
            <p class="text-muted">Naam en e-mailadres worden standaard gevraagd en zijn verplicht.</p>
            <b-form-checkbox-group v-model="form.extra_details" :options="extraDetailsOptions" stacked />
        </div>
        <template v-if="form.extra_details">
            <hr />
            <div class="mb-3">
            <label>Welke gegevens zijn verplicht?</label>
            <b-form-checkbox-group v-model="form.extra_details_required" :options="dynamicExtraDetailOptions" stacked />
            </div>
        </template>
        </b-card>

        <b-card class="mb-4">
        <h3 class="fs-4 font-weight-bold mb-0">Persoonlijke tickets</h3>
        <p class="text-muted"> Met een persoonlijke ticket moet er bij het bestellen bij elke ticket de naam opgegeven worden, tickets zijn hierdoor persoonsgebonden.</p>
        <div class="mb-3">
            <label>Persoonlijke tickets inschakelen</label>
            <b-form-select v-model="form.is_personal_ticket" :options="yesNoOptions" />
        </div>
        <template v-if="form.is_personal_ticket">
            <hr />
            <div class="mb-3">
            <label>Welke gegevens zijn verplicht?</label>
            <b-form-checkbox-group v-model="form.personal_details_required" :options="personalDetailRequiredOptions" stacked />
            </div>
        </template>
        </b-card>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        form: Object,
    },
    data() {
        return {
            extraDetailsOptions: [
              { text: 'Bedrijfsnaam', value: 'company_name' },
              { text: 'Geboortedatum', value: 'birthdate' },
              { text: 'Geslacht', value: 'gender' },
              { text: 'Telefoonnummer', value: 'phone' },
              { text: 'Adres', value: 'address' },
              { text: 'Postcode', value: 'zipcode' },
              { text: 'Woonplaats ', value: 'city ' },
            ],
            personalDetailRequiredOptions: [
              { text: 'E-mail', value: 'email' },
              { text: 'Telefoonnummer', value: 'phone' },
              { text: 'Geboortedatum', value: 'birthdate' },
              { text: 'Geslacht', value: 'gender' },
            ],
            yesNoOptions: [
              { text: 'Ja', value: true },
              { text: 'Nee', value: false }, 
            ]
        }
    },
    computed: {
        dynamicExtraDetailOptions() {

            let options = [];

            this.extraDetailsOptions.forEach(item => {
                this.form.extra_details.forEach(selected => {
                    if (item.value == selected) {
                        options.push({ text: item.text, value: item.value });
                    }
                });
            });

            return options;
        },
    },
    methods: {},
    created() {},
}
</script>